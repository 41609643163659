<template>
  <label
    ref="label"
    class="border rounded p-3 flex justify-between items-center cursor-pointer relative"
    :class="
      isSelected
        ? 'bg-primary bg-opacity-10 border-primary/30'
        : 'bg-white border-divider'
    "
  >
    <lf-checkbox
      name="selected_stips"
      :value="stip.id"
      v-model="selectionProxy"
      truncate
      v-tooltip="stip.name"
      class="truncate"
      :data-cy="`stip-${stip.id}`"
    >
      <span :title="stip.name">{{ stip.name }}</span>
    </lf-checkbox>
    <tooltip
      ref="descriptionTooltip"
      placement="left"
      :offset="[0, 0]"
      trigger="click"
      hide-on-click
      sticky="reference"
      :arrow="false"
      theme="none"
      interactive
      :on-hidden="resetEditedStip"
    >
      <template #default>
        <icon-base
          :icon="IconNotes"
          class="cursor-pointer"
          :class="{ 'text-primary': stip.description }"
          v-tooltip="stip.description || $t('STIPS.MANAGE_DESCRIPTION')"
          @click.prevent
        />
      </template>
      <template #content>
        <div
          class="p-2 bg-white shadow-lg rounded gap-2 flex flex-col border border-blue-200 min-w-52"
          :style="{ width: `${label?.clientWidth}px` }"
        >
          <lf-textarea
            :placeholder="$t('STIPS.ADD_DESCRIPTION')"
            rows="4"
            :name="`stip-description-${stip.id}`"
            noresize
            :character-limit="TEXT_CHARACTER_LIMIT_MEDIUM"
            :value="editedStip.description"
            @key-released="editedStip.description = $event"
          />
          <modal-buttons
            class="grow justify-end px-[0]"
            :on-cancel="resetEditedStip"
            :on-save="updateStip"
            @click.prevent
          />
        </div>
      </template>
    </tooltip>
  </label>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import LfCheckbox from "@/components/ui/inputs/LfCheckbox.vue";
import IconBase from "@/components/ui/IconBase.vue";
import IconNotes from "@/components/icons/IconNotes.vue";
import ModalButtons from "@/components/ui/ModalButtons.vue";
import { TEXT_CHARACTER_LIMIT_MEDIUM } from "@/helpers/constants";
import type { IStip } from "@/models/applications";
import type { TippyComponent } from "vue-tippy";

const emit = defineEmits<{
  "update:model-value": [number[]];
  "update:stip": [IStip];
}>();

const props = defineProps<{
  stip: IStip;
  modelValue: number[];
}>();

const label = ref<HTMLLabelElement | null>(null);
const editedStip = ref({ ...props.stip });
const descriptionTooltip = ref<TippyComponent | null>(null);

const isSelected = computed(() => props.modelValue.includes(props.stip.id));

const selectionProxy = computed({
  get() {
    return props.modelValue;
  },
  set(newVal) {
    emit("update:model-value", newVal);
  }
});

const resetEditedStip = () => {
  if (descriptionTooltip.value?.state.isShown) {
    descriptionTooltip.value.hide();
  }
  editedStip.value = { ...props.stip };
};

const updateStip = () => {
  emit("update:stip", editedStip.value);
  resetEditedStip();
};
</script>
